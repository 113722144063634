<template>
<div class="home">
  <div class="flex">
    <div class="item-3">
      <!-- <cloumn-item class="small-item" :title="`街区供电情况分析：`">
        <powered slot></powered>
      </cloumn-item> -->
      <cloumn-item class="small-item box01" style="height:1080px" :title="`智慧消防设备统计：`">
        <fireProofDevices slot></fireProofDevices>
      </cloumn-item>
    </div>
    <div class="stub-big-item">
    </div>
    <div class="item-3">
      <cloumn-item class="small-item" :title="`设备维保数据统计：`">
        <maintains slot></maintains>
      </cloumn-item>
      <cloumn-item class="small-item maintenanceMan" :title="`维护人员情况：`">
        <maintenanceMan slot></maintenanceMan>
      </cloumn-item>
      <cloumn-item class="small-item" :title="`消防告警信息：`">
        <fireAlarmInfo slot></fireAlarmInfo>
      </cloumn-item>
    </div>
  </div>

</div>
</template>

<script>
import cloumnItem from "@/components/home/cloumnItem";
// import powered from "@/components/equipment/powered";
import fireProofDevices from "@/components/equipment/fireProofDevices";
import maintains from "@/components/equipment/maintains";
import maintenanceMan from "@/components/equipment/maintenanceMan";
import fireAlarmInfo from "@/components/equipment/fireAlarmInfo";
// import { getFireproofDevices } from "@/api/api.js";

export default {
  name: "Home",
  components: {
    cloumnItem,
    // powered,
    fireProofDevices,
    maintains,
    maintenanceMan,
    fireAlarmInfo
  },
  data() {
    return {
      data: {},
      timer: 0,
    };
  },
  methods: {
    getData() {
      // getFireproofDevices().then((res) => {
      //   return (this.data = res.data.rows);
      // });
    },
  },
  mounted() {
    this.getData();
    this.timer = setInterval(this.getData, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
/* .echarts {
  width: 100%;
  height: 100%;
} */
.item-2{
  display: none;
}
.small-item {
  width: 600px;
  height: 340px;
}

.stub-big-item {
  width: 1180px;
  height: 1080px;
  margin-right: 30px;
}

.cloumn-bottom .big-item {
  height: 340px;
}

.item-3:first-child,
.cloumn-bottom .small-item:first-child {
  margin-right: 30px;
}

.item-3 .small-item {
  margin-bottom: 30px;
}

.flex {
  align-items: start;
}
.padding-0{padding:20px 0 0}
.box01{overflow:hidden}
</style>
