<template>
  <section class="store-info">
    <div class="device-list flex">
      <p>设备名称:</p>
      <select v-model="device_type">
        <option v-for = "(device_type, i) in device_types" :value="device_type.code" :key="i">{{device_type.name}}</option>
      </select>
      <select v-model="pageNum">
        <option v-for = "(page, i) in totalPage" :value="page" :key="i">{{page}}</option>
      </select>
    </div>
    <div class="store-list">
      <table>
        <thead>
          <tr>
            <th>
              <div class="bg">设备名称</div>
            </th>
            <th>
              <div class="bg">设备系统</div>
            </th>
            <th>
              <div class="bg">状态</div>
            </th>
          </tr>
        </thead>
        <tbody class="store-list-wrap">
          <tr v-for="(device, i) in devices" :key="i">
            <td>
              <div class="bg2">{{ device.device_name }}</div>
            </td>
            <td>
              <div class="bg2">
                {{ device.system_type }}
              </div>
            </td>
            <td>
              <div class="bg2">{{ device.device_status }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import { getFireproofDevices } from "@/api/api.js";

export default {
  data() {
    return {
      devices: [],
      timer: 0,
      device_types:[
        {code:600001,name:"组合式电气火灾监控探测器"},
        {code:600002,name:"独立式光电感烟探测器（NB)"},
        {code:600003,name:"物联网消防报警网关（433）"},
        {code:600005,name:"用户信息传输装置"},
        {code:600006,name:"独立式可燃气体探测器（NB）"},
        {code:600007,name:"室外消火栓智能采集终端"},
        {code:600008,name:"智慧用水采集终端"},
        {code:600009,name:"消防智能分析仪"},
        {code:600010,name:"视频烟感"},
        {code:600011,name:"视频温感"},
        {code:600012,name:"网关摄像机"},
        {code:600013,name:"充电桩"},
        {code:600014,name:"独立式可燃气体探测器"},
        {code:600015,name:"小型壁挂式火灾报警控制器"},
        {code:600016,name:"热成像设备"},
        {code:600017,name:"空开智能网关"},
        {code:600018,name:"LORA网关"},
        {code:600019,name:"门磁"},
      ],
      device_type:'600001',
      rows: '22',
      total:'',
      pageNum: 1,
    };
  },
  methods: {
    getDataFromType() {
      getFireproofDevices(this.device_type, this.rows, this.pageNum).then((res) => {
        let onlineDevices = res.data.rows.filter((item) => { return item.device_status == '在线' })
        let unRegisterDevices = res.data.rows.filter((item) => { return item.device_status == '未注册' })
        let offlineDevices = res.data.rows.filter((item) => { return item.device_status == '离线' })
        this.total = res.data.total
        this.pageInterval()
        return this.devices = onlineDevices.concat(unRegisterDevices).concat(offlineDevices);
      });
    },
    pageInterval(){
      this.timer = setInterval(() => {
        if (this.totalPage > this.pageNum) {
          this.pageNum++;
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 10000)
    }
  },
  mounted() {
    this.getDataFromType()
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch:{
    device_type(){
      this.getDataFromType()
      this.pageNum = 1
    },
    pageNum(){
      this.getDataFromType()
    }
  },
  computed:{
    totalPage(){
      if(this.total > this.rows){
        return Math.ceil(this.total/this.rows)
      }else {
        return 1
      }
    }
  }
};
</script>
<style scoped>
.images {
  padding: 0 30px;
}
.images img {
  border-radius: 5px;
}
.images li {
  width: 23%;
}
p {
  font-size: 18px;
  color: #fff;
}
.store-info table {width:100%;color:#fff;margin-top:18px;font-size:16px}
.store-info table .bg {
  background: #15363b;
  padding: 8px;
  margin-bottom: 3px;
}

.store-info table .bg2 {
  background: rgba(14, 42, 48, 0.7);
  padding: 8px;
  margin-bottom: 3px;
}


.store-info table td:first-child .bg2,
.store-info table th:first-child .bg {
  border-radius: 10px 0 0 10px;
}

.store-info table td:last-child .bg2,
.store-info table th:last-child .bg {
  border-radius: 0 10px 10px 0;height: 39px;
}

.store-info table td,
.store-info table thead th {
  padding: 0;text-align:center
}

.store-info .event-icon {
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.store-title {
  font-size: 18px;
  color: #fff;
  margin-bottom: 16px;
}

.store-list {
  height: 1030px;
  overflow: hidden;
}
.store-list thead {
  position: relative;
  z-index: 1;
}
.store-list-wrap {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.store-list .animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateY(-40px);
}
.device-list p{margin:10px  0;font-size:16px}
.device-list select{padding:5px;margin:0 5px}
</style>
