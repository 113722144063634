<template>
  <section class="store-info">
    <div class="device-list flex">
      <p>设备名称:</p>
      <select v-model="state">
        <option v-for = "(state, i) in process_state" :value="state.code" :key="i">{{state.state}}</option>
      </select>
    </div>
    <div class="store-list">
      <table>
        <thead>
          <tr>
            <th>
              <div class="bg">报警源名称</div>
            </th>
            <th>
              <div class="bg">报警类型</div>
            </th>
            <th>
              <div class="bg">处理结果</div>
            </th>
          </tr>
        </thead>
        <tbody class="store-list-wrap" :class="{ 'animate-up': animateUp }">
          <tr v-for="(alarm, i) in alarms" :key="i">
            <td>
              <div class="bg2">{{ alarm.resource_name }}</div>
            </td>
            <td>
              <div class="bg2">
                {{ alarm.alarm_type }}
              </div>
            </td>
            <td>
              <div class="bg2">{{ alarm.process_result }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import { getfireproof_alarm } from "@/api/api.js";

export default {
  data() {
    return {
      alarms: [],
      timer: 0,
      animateUp:false,
      process_state:[
        {code:0,state:"未处理"},
        {code:1,state:"已处理"},
        {code:2,state:"处理中"},
      ],
      state:'1',
      rows: '50',
      total:'',
      pageNum: '1',
    };
  },
  methods: {
    getDataFromType() {
      getfireproof_alarm(this.state, this.rows, this.pageNum).then((res) => {
        let pendingAlarm = res.data.rows.filter((item) => { return item.process_state == '未处理' })
        let solvedAlarm= res.data.rows.filter((item) => { return item.process_state == '已处理' && item.process_result != "测试" })
        let processingAlarm= res.data.rows.filter((item) => { return item.process_state == '处理中' })
        this.total = res.data.total
        if(this.total > 5){
          this.timer = setInterval(this.scrollAnimate, 3000);
        } else{
          clearInterval(this.timer);
        }
        return this.alarms = pendingAlarm.concat(solvedAlarm).concat(processingAlarm);
      });
    },
    scrollAnimate() {
      this.animateUp = true;
      setTimeout(() => {
        this.alarms.push(this.alarms[0]);
        this.alarms.shift();
        this.animateUp = false;
      }, 500);
    },

  },
  mounted() {
    this.getDataFromType()
    // this.getData()
    // this.timer = setInterval(this.getData, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch:{
    state(){
      this.getDataFromType(this.state,this.rows,this.pageNum)
    },
  },
  computed:{
    totalPage(){
      if(this.total > this.rows){
        return Math.ceil(this.total/this.rows)
      }else {
        return 1
      }
    }
  }
};
</script>
<style scoped>
.device-list.flex {
  position: absolute;
  top: -28px;
  right: 20px;
  align-items: baseline;
}
.images {
  padding: 0 30px;
}
.images img {
  border-radius: 5px;
}
.images li {
  width: 23%;
}
p {
  font-size: 18px;
  color: #fff;
}
.store-info{position: relative;}
.store-info table {width:100%;color:#fff;margin-top:18px;font-size:16px}
.store-info table .bg {
  background: #15363b;
  padding: 8px;
  margin-bottom: 3px;
}

.store-info table .bg2 {
  background: rgba(14, 42, 48, 0.7);
  padding: 8px;
  margin-bottom: 3px;
}


.store-info table td:first-child .bg2,
.store-info table th:first-child .bg {
  border-radius: 10px 0 0 10px;
}

.store-info table td:last-child .bg2,
.store-info table th:last-child .bg {
  border-radius: 0 10px 10px 0;height: 39px;
}

.store-info table td,
.store-info table thead th {
  padding: 0;text-align:center
}

.store-info .event-icon {
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.store-title {
  font-size: 18px;
  color: #fff;
  margin-bottom: 16px;
}

.store-list {
  height: 270px;
  overflow: hidden;
}
.store-list thead {
  position: relative;
  z-index: 1;
}
.store-list-wrap {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.store-list .animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateY(-40px);
}

.device-list p{margin:10px 10px 10px 0;font-size:16px}
.device-list select{padding:5px}
</style>
